/* Reset */
/* Defaults */
/* Atomics */
/* Dynamics */
/* Variables */
/* Color */
/* Theme */
/* Page */
/* Home Page */
    /* Data Tiles */
    /* Graphs */
    /* Target Controls */
/* Fixed Bottom */
    /* Watch */
    /* Menu */
/* Workout Page */
    /* Workout List */
    /* Ride Report */
    /* Editor */
/* Settings Page */
    /* ANT Device Request */
    /* App Connections */
    /* User Auth */
    /* About */
/* Media Queries */

/* Reset */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
nav ul, ul.clean {
    margin-left: 0;
    list-style-type: none!important;
}
a, a:visited {
    text-decoration: none;
}
h1, h2 {
    font-weight: normal;
}
h3, h4, h5, h6 {
    font-weight: normal;
}
a:focus {
    outline: none;
}
a::-moz-focus-inner {
    border: 0;
}
input, select, textarea {
    outline: 0;
}
input[type="button"] {
    outline: none;
}
input[type="button"]::-moz-focus-inner {
    border: 0;
}
button {
    outline: none;
}
button::-moz-focus-inner {
    border: 0;
}
img {
    outline: 0!important;
    border: 0!important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* END Reset */

/* Defaults */
html {
    font-size: 10px;
    text-align: center;
}

body {
    position: relative;
    width: 100%;
    height: 100%;
    line-height: 1.4em;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

header, footer, section, article, nav {
    display: block;
}

input, button, textarea {
    font-family: var(--ssr-font-family);
}

input {
    color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type="number"] {
    -webkit-appearance: none !important;
    margin: 0;
}

.btn {
    color: #fff;
    background-color: var(--chrome);
    text-transform: uppercase;
    font-family: var(--ss-font-family);

    border: 1px solid var(--white-opacity-01);
    border-radius: 3px;
    webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
}

.btn:hover {
    background-color: #777;
    cursor: pointer;
}

.flat-btn {
    background-color: transparent;
    border: none;
    color: #fff;
}

.request-btn {
    padding: 5px 10px;

    color: #fff;
    background-color: var(--chrome);
    font-family: var(--ss-font-family);

    border: 1px solid var(--white-opacity-01);
    border-radius: 3px;
    webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
}

.file-btn {
    position: relative;
    height: 30px;
    margin: 0px auto 20px;
    color: var(--gray);
}

.file-btn-native {
    position: relative;
    margin-left: 32px;
    line-height: 27px;
    height: 30px;
    z-index: 0;
    border: 1px solid var(--dark);
}

.file-btn-label {
    display: inline-block;
    height: 30px;
    padding: 0 14px;
    font-family: sans-serif;
    font-size: 8px;
    letter-spacing: 1px;
    line-height: 29px;
    text-transform: uppercase;
    color: #fff;
    background-color: var(--chrome);
    border-radius: 3px;

    position: absolute;
    width: 120px;
    z-index: 1;
}
/* END Defaults */

/* Atomics */
.h1, .h2, .h3, h4, .h5, .t1, .t2, .t3, .t4, .t5, .t6,
.a, .text, button, .btn,
input, svg, a,
.flat-btn,
.settings-switch-bnt {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.a {
    color: #bbb;
    text-transform: uppercase;
    cursor: pointer;
}

.line {
    display: block;
    vertical-align: middle;
    width: 100%;
    height: 1px;
    margin: 0 auto;
    background-color: var(--white-opacity-01);
}

/* Clearfix */
.cf:before, .cf:after {
    content: " ";
    display: table;
}
.cf:after {
    clear: both;
}
.cf {
    /* *zoom: 1; */
    /*For IE 6/7 only*/
}
/* End Clearfix */
/* End Atomics */

/* Dynamics */
.hide {
    visibility: hidden;
}
/* End */

/* Variables */
:root {
    --ssb-font-family: 'Roboto', sans-serif;
    --ssr-font-family: 'Roboto', sans-serif;
}

body {

    /* Color */
    --black:       #000;
    --white:       #fff;
    --gray:        #aaa;
    --green:       #40FF70;
    --red:         #FF495F;
    --yellow:      #F8C73A;
    --dark:        #28272D;
    --chrome:      #35363a;

    --zone-red:    #FE340B;
    --zone-orange: #FF663A;
    --zone-yellow: #F8C73A;
    --zone-green:  #57C057;
    --zone-teal:   #44A5AB;
    --zone-blue:   #328AFF;
    --zone-gray:   #636468;

    --orange-logo: #FC4C02;
    --white-gray:  #F7F7FA;
    --red-logo:    #E50914;

    --progress-white: #fff4;

    --black-opacity-01:  rgba(0,0,0, 0.1);
    --black-opacity-02:  rgba(0,0,0, 0.2);
    --white-opacity-01:  rgba(255,255,255, 0.1);
    --white-opacity-03:  rgba(255,255,255, 0.3);
}
/* END Variables */

/* Color */
.zone-one {
    background-color: var(--zone-gray);
}
.zone-two {
    background-color: var(--zone-blue);
}
.zone-three {
    background-color: var(--zone-teal);
}
.zone-four {
    background-color: var(--zone-green);
}
.zone-five {
    background-color: var(--zone-yellow);
}
.zone-six {
    background-color: var(--zone-orange);
}
.zone-seven {
    background-color: var(--zone-red);
}

.zone-one-color {
    color: var(--zone-gray);
}
.zone-two-color {
    color: var(--zone-blue);
}
.zone-three-color {
    color: var(--zone-teal);
}
.zone-four-color {
    color: var(--zone-green);
}
.zone-five-color {
    color: var(--zone-yellow);
}
.zone-six-color {
    color: var(--zone-orange);
}
.zone-seven-color {
    color: var(--zone-red);
}

.black {
    color: var(--black);
}
.white {
    color: var(--white);
}
.gray {
    color: var(--gray);
}
.yellow {
    color: var(--yellow);
}
.red {
    color: var(--red);
}
.green {
    color: var(--green)
}

.black-bg {
    background-color: var(--black);
}
.white-bg {
    background-color: var(--white);
}
.gray-bg {
    background-color: var(--gray);
}
.yellow-bg {
    background-color: var(--yellow);
}
.red-bg {
    background-color: var(--red);
}
.green-bg {
    background-color: var(--green);
}


.gray-zone {
    background-color: var(--zone-gray);
}
.blue-zone {
    background-color: var(--zone-blue);
}
.green-zone {
    background-color: var(--zone-green);
}
.yellow-zone {
    background-color: var(--zone-yellow);
}
.orange-zone {
    background-color: var(--zone-orange);
}
.red-zone {
    background-color: var(--zone-red);
}
.purple-zone {
    background-color: var(--zone-red);
}

.chrome-bg {
    background-color: var(--chrome)
}
.dark-bg {
    background-color: var(--dark);
}
/* End Color */

/* Theme */
/* Dark Theme */
body {
    color: var(--white);
    background-color: var(--dark);
}
.connections-header {
    background-color: var(--chrome);
}

#progress-active {
    background-color: transparent;
}
.radio {
    fill: var(--white);
}

.mode.active {
    color: #fff;
}

.icon-btn .icon {
    fill: var(--gray);
}
.icon-btn .text {
    color: var(--gray);
}
.tab-btn.active .text {
    color: var(--white);
}
.tab-btn.active .icon-fill {
    fill: var(--white);
}
.control-icon {
    fill: #fff;
}
/* Dark Theme */

/* Page */
.wrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    align-content: center;
    overflow-x: hidden;

    font-family: var(--ssr-font-family);
    /* font-variant-numeric: tabular-nums; */
}
.page {
    width: 100%;
    min-height: 110vh;
    padding-bottom: 12em;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
}

.page {
    display: none;
}

.page.active {
    display: block;
}

.page--header {
    width: 100%;
}

.page--heading {
    font-size: 1.1em;
    text-transform: uppercase;
    line-height: 4em;
    text-align: center;
    letter-spacing: 1px;
}

#workouts-page .page--header {
    margin-bottom: 40px;
}
/* End Page */

/* Home Page */
#home-page {
    min-height: 100vh;
    padding: 0;
    z-index: 1;
}

#home-page-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50px 2.5fr 1fr 1fr 98px 100px;
    height: 100vh;
}

#compatibility--cont {
    font-size: 1.4em;
    width: 100%;
    padding: 1em 0.5em;
    line-height: 1.5em;
    background-color: var(--red);
    border-bottom: 1px solid var(--dark);
    z-index: 1;
}

#compatibility--cont a {
    color: var(--yellow);
}

/* Switch btn */
.connections-header {
    font-size: 1.1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    grid-row: 1;
}

.connection-switch,
.protocol-switch {
    display: flex;
    align-items: center;
    align-content: center;
    height: 3em;
    padding: 0 15px;
}

.connection-switch:hover,
.connection-icon-switch:hover {
    cursor: pointer;
}

.connection-switch--indicator {
    margin: 0px 1em 0px 0px;
    width: 1.3em;
    height: 1.3em;
    border-radius: 50%;
}

.connection-switch--indicator.on,
.connection-icon-switch--indicator.on {
    background-color: var(--green);
}

.connection-switch--indicator.loading,
.connection-icon-switch--indicator.loading {
    background-color: var(--yellow);
}

.connection-switch--indicator.off,
.connection-icon-switch--indicator.off {
    background-color: var(--red);
}

.connection-switch--label {
    font-size: 1em;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.protocol-switch.on {
    color: var(--white);
}

.protocol-switch.loading {
    color: var(--gray);
}

.protocol-switch.off {
    color: var(--gray);
}

.protocol-switch.on .protocol-btn--icon {
    fill: var(--white);
}

.protocol-switch.loading .protocol-btn--icon {
    fill: var(--gray);
}

.protocol-switch.off .protocol-btn--icon {
    fill: var(--gray);
}

.protocol-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.protocol-btn--icon {
    fill: var(--white);
    width: var(--size--icon--global);
    height: var(--size--icon--global);
}

.protocol-btn--label {
    margin-left: 10px;
    font-weight: bold;
}

/* Data Tiles */
.data-tiles {
    position: relative;
    grid-row: 2;

    font-size: 1rem;
    --data-tile--heading-font-size: 1.0em;
    --data-tile--value-font-size: 3.2em;
    --data-tile--unit-font-size: 1em;

    --data-tile-small--heading-font-size: 1.0em;
    --data-tile-small--value-font-size: 2.6em;
    --data-tile-small--unit-font-size: 0.6em;

    --data-tile--heading-margin: 0 auto 1em;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, 1fr) 2rem;
    justify-items: center;
    align-items: center;
}

#data-tile--power {
    grid-area: 1 / 1 / 1 / 5;
}
#data-tile--interval-time {
    grid-area: 1 / 5 / 1 / 9;
}
#data-tile--heart-rate {
    grid-area: 1 / 9 / 1 / 13;
}
#data-tile--target {
    grid-area: 2 / 1 / 2 / 5;
    cursor: pointer;
}
#data-tile--elapsed-time {
    grid-area: 2 / 5 / 2 / 9;
}
#data-tile--cadence {
    grid-area: 2 / 9 / 2 / 13;
}
#data-tile--speed {
    grid-area: 3 / 1 / 3 / 4;
    display: none;
}
#data-tile--distance {
    grid-area: 3 / 4 / 3 / 7;
    display: none;
}
#data-tile--slope {
    display: none;
    grid-area: 3 / 7 / 3 / 10;
}
#data-tile--power-avg {
    display: none;
    grid-area: 3 / 10 / 3 / 13;
}
#data-tile--smo2 {
    grid-area: 3 / 1 / 3 / 4;
    display: none;
}
#data-tile--thb {
    grid-area: 3 / 4 / 3 / 7;
    display: none;
}
#data-tile--core-body-temperature {
    grid-area: 3 / 7 / 3 / 10;
    display: none;
}
#data-tile--skin-temperature {
    grid-area: 3 / 10 / 3 / 13;
    display: none;
}


#data-tile--power-avg.active {
    display: block;
}
#data-tile--slope.active {
    display: block;
}
#data-tile--speed.active {
    display: block;
}
#data-tile--distance.active {
    display: block;
}

#data-tile--smo2.active {
    display: block;
}

#data-tile--thb.active {
    display: block;
}

#data-tile--core-body-temperature.active {
    display: block;
}
#data-tile--skin-temperature.active {
    display: block;
}

.data-tile--switch--group {
    width: 100%;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: 4 / 1 / 4 / 13;
}

.data-tile--switch {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 6rem;
}

.data-tile--switch .data-tile--switch--icon {
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 1px solid #fff;
}

.data-tile--switch.active .data-tile--switch--icon {
    background-color: #fff;
    border: 1px solid #fff;
}

.data-tile-small--unit {
    display: none;
}

.data-tile--heading {
    font-size: var(--data-tile--heading-font-size);
    margin: var(--data-tile--heading-margin);
    font-family: var(--ssr-font-family);
    text-transform: uppercase;
}

.data-tile--value {
    font-size: var(--data-tile--value-font-size);
    line-height: 1em;
    font-family: var(--ssb-font-family);
    font-weight: bold;
}

.data-tile-small--heading {
    margin: var(--data-tile--heading-margin);
    font-size: var(--data-tile-small--heading-font-size);
    text-transform: uppercase;
}

.data-tile-small--heading sub {
    text-transform: lowercase;
}

.data-tile-small--value {
    font-size: var(--data-tile-small--value-font-size);
    font-weight: bold;
    line-height: 1em;
}

.data-tile-small--unit {
    font-size: var(--data-tile-small--unit-font-size);
    font-weight: bold;
}

.data-tile--value-cont.complex {
    height: 3.2em;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.data-tile--value.active {
    font-size: 2.6em;
    width: 2em;
}

.data-tile-target--value {
    display: none;
    width: 0;
}
.data-tile-target--value.active {
    display: block;
    font-size: 1.6em;
    width: auto;
    min-width: 1.5em;
}

z-stack-item {
    display: none;
    cursor: pointer;
}

z-stack-item.active {
    display: block;
}

/* END Data Tiles */

/* Graphs */
.graph {
    font-size: 1rem;
    --graph-total-height: 100%;
    /* --graph-height: 90%; */
    /* --graph-heading-height: 10%; */

    position: relative;
    height: var(--graph-total-height);
    width: 100%;

    display: grid;
    grid-template-rows: minmax(1.4rem, auto) minmax(0, 100%);

    border-top: 1px solid var(--white-opacity-01);
}
#graph-power {
    overflow: hidden;
}

div.graph--bar-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0;
}
#graph--info--cont {
    display: none;
    font-size: 1.4em;
    line-height: 1.2em;
    position: absolute;
    padding: 0.4em 0.4em;
    color: var(--black);
    white-space: nowrap;
    background-color: var(--white);
    border-radius: 0.2em;
    z-index: 2;
}

#workout-list svg.graph--bar-group {
    height: var(--graph-height);
    height: 8em;
}

.graph--heading {
    position: relative;
    font-family: var(--ssb-font-family);
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* height: var(--graph-heading-height); */
    z-index: 1;
}

.elevation--value {
    position: absolute;
    top: 0.4em;
    font-size: 1.6em;
    font-weight: bold;
}
.ascent--value {
    right: 0.3em;
}
.altitude--value {
    left: 0.3em;
}

.graph--msg {
    font-size: 1em;
    line-height: 1.4em;

    position: relative;
    z-index: 1;
}

.graph--cont {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    bottom: 0px;
    padding: 0;
}

.graph--bar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    width: 1px;

}

.graph--info {
    display: none;
    position: absolute;
    bottom: 60%;
    padding: 2px 2px;
    color: var(--black);
    background-color: var(--white);
    border-radius: 2px;
    z-index: 1;
}

.graph--bar:hover .graph--info {
    display: block;
}

.graph--info--power {
    min-width: 48px;
}

.graph--info--slope {
    min-width: 48px;
}

.graph--info--cadence {
    min-width: 48px;
}

.graph--info--time {
    color: var(--gray);
}

.graph--bar-distance {
}

.workout--graph-cont {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-end;
    height: var(--graph-height);
    height: 100%;
    padding: 0;
}

.workout-list--graph-cont {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    /* min-height: 100%; */
    /* min-height: var(--list--graph--height); */
    padding: 0;
}

#progress {
    position: absolute;
    bottom: 0px;
    left: 0;
    display: flex;
    justify-content: start;
    align-items: flex-end;
    width: 0px;
    height: 100%;
    background: var(--progress-white);
    border-right: 1px solid var(--zone-orange);
    z-index: 1;
    pointer-events: none;
}

#progress-active {
    display: flex;
    justify-content: start;
    align-items: flex-end;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 0px;
    height: var(--graph-height);
    z-index: 1;
    pointer-events: none;
}
/* END Graphs */

/* Target Controls */
#targets {
    font-size: 1rem;
    padding: 0 1em;
    --inner-max-width: 300px;
    --mode-selector-height: 3rem;
    --mode-selector-margin: 0.8em auto;
    --mode-selector-padding: 0 1em;
    --mode-width: 100%;

    --number-input-height: 3rem;
    --number-input-total-width: 100%;

    --number-input-value-width: 40%;
    --number-input-width: 30%;

    --number-input-font-size: 1.2em;
    --number-input-value-font-size: 1.4em;
}

.targets--cont {
    margin: 0 auto;
    max-width: var(--inner-max-width);
}

.mode-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    max-width: 100%;
    height: var(--mode-selector-height);
    margin: var(--mode-selector-margin);
    font-size: 1em;
    text-transform: uppercase;
}

.mode {
    width: var(--mode-width);
    height: var(--mode-selector-height);
    line-height: var(--mode-selector-height);
    padding: var(--mode-selector-padding);
    color: var(--gray);
    cursor: pointer;
}

.mode.active {
    font-weight: bold;
}

.number-input {
    display: flex;
    justify-content: center;
    align-items: center;
}

.number-input--value, .number-input--inc, .number-input--dec {
    height: var(--number-input-height);
}

.number-input--value {
    width: var(--number-input-value-width);
    line-height: var(--number-input-line-height);
    font-size: var(--number-input-value-font-size);
    text-align: center;
    color: var(--dark);
    border: 1px solid var(--white-opacity-01);
}

.number-input--btn {
    font-size: var(--number-input-font-size);
    width: var(--number-input-width);
    text-align: center;
}

.number-input--inc {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.number-input--dec {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
/* END Target Controls */
/* END Home Page */

/* Fixed Bottom */
.fixed-bottom {
    border-top: 1px solid var(--white-opacity-01);
    font-size: 1rem;

    --controls-height: 5em;
    --menu-height: 5em;
    --fb-total-height: 10em;
    --size--icon--global: 2.4em;
    --size--icon--menu--btn: 2em;

    position: fixed;
    bottom: 0px;
    width: 100%;
    height: var(--fb-total-height);

    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;

    background-color: var(--dark);
    z-index: 3;
}

/* .fixed-bottom--cont { */
/*     height: var(--controls-height); */
/*     width: 100%; */
/*     /\* border-top: 1px solid var(--white-opacity-01); *\/ */
/*     z-index: 1; */
/* } */

#workout-name {
    display: block;
    font-size: 1.2em;
    font-family: var(--ssb-font-family);
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    display: block;
    margin: 0 0.8em;
    text-align: left;

    grid-area: 1 / 1 / 2 / 4;
}

/* Watch */
.watch {
    grid-area: 1 / 4 / 2 / 7;
    justify-self: right;
}

.watch .btn {
    margin: 0px 2px;
}

.control--btn {
    display: inline-block;
    margin: 0px 1.2em;
}

.control--btn--icon {
    width: var(--size--icon--global);
    height: var(--size--icon--global);
    fill: #fff;
    cursor: pointer;
}

#watch-pause, #watch-lap, #watch-stop, #activity-save {
    display: none;

}
/* END Watch */

/* Menu */
.tab.active {
    display: block;
}

.tab {
    display: none;
}

.menu {
    height: var(--menu-height);
    grid-area: 2 / 1 / 2 / 7;
}

.menu--row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    wrap: wrap;
    height: 100%;
    z-index: 1;
}

.menu--btn {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.menu--btn--icon {
    display: block;
    width: var(--size--icon--menu--btn);
    height: var(--size--icon--menu-btn);
    fill: var(--gray);
}

.menu--btn--label {
    font-size: 1em;
    line-height: 1.4em;
    letter-spacing: 1px;
    color: var(--gray);
}

.menu--btn--icon--fill {
    fill: var(--gray);
}

.menu--btn.active .menu--btn--label {
    color: var(--white);
}

.menu--btn.active .menu--btn--icon--fill {
    fill: var(--white);
}
/* END Menu */
/* END Fixed Bottom */

/* #dark-mode { */
/*     display: inline-block; */
/*     margin: 20px auto 20px; */
/*     width: 105px; */
/* } */


/* Workout Page */
#workouts-page {
    font-size: 1rem;

    --workout--name-font-size: 1.4em;
    --workout--type-font-size: 1.4em;
    --workout--duration-font-size: 1.4em;
    --workout--description-font-size: 1.4em;
    --inner-max-width: 60rem;
    --size--icon--global: 2.4em;
    --list--graph--height: 8em;

    text-align: center;
}

/* Workout List */
.workout {
    display: block;
    position: relative;
    border-bottom: 1px solid var(--white-opacity-01);
}

.workout--info {
    position: relative;
    padding: 0em 1.5em;
    background-color: var(--dark);
    z-index: 1;
}

.options-show {
    transform: translateX(-8rem);
}

.options-hide {
    transform: translateX(0);
}

.workout--actions {
    position: absolute;
    display: grid;
    top: 0;
    right: 0;
    bottom: 0;
    width: 8em;
    background-color: var(--red);
    z-index: 0;
}

.workout--remove {
    font-size: 1.4em;
    justify-self: center;
    align-self: center;
    cursor: pointer;
}

.workout--options {
    display: flex;
    justify-content: center;
}
.workout--options-btn {
    pointer-events: none;
}

.workout--short-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    max-width: var(--inner-max-width);
    margin: 0 auto;
}

.workout--summary {
    display: grid;
    grid-template-columns: 5fr 3fr 2fr 1fr 1fr;

    align-items: center;
    align-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 1.5em 0;
    text-align: left;
    cursor: pointer;
}

.workout--name {
    font-size: var(--workout--name-font-size);
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.workout--type {
    font-size: var(--workout--type-font-size);
    /* min-width: 100px; */
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.workout--duration {
    font-size: var(--workout--duration-font-size);
    /* min-width: 60px; */
    color: var(--gray);
}

.workout--full-info {
    display: none;
}

.workout--description {
    font-size: var(--workout--description-font-size);
    max-width: var(--inner-max-width);
    margin: 0 auto;
    line-height: 1.4em;
    padding: 1.5em 0 0.5em;
    text-align: left;
}
.radio {
    width: var(--size--icon--global);
    height: var(--size--icon--global);
}

.workout-loader--note {
    font-size: var(--workout--description-font-size);
    line-height: 1.4em;
    margin: 0 2em 2em 2em;
    color: var(--gray);
}
/* END Workout List */

/* Ride Report */
#power-in-zone {

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    justify-items: center;
    align-items: center;
    max-width: var(--inner-max-width);
    margin: 20px auto;
    padding: 0 1em;
    height: 4em;
}

.power--zone--item {
    position: relative;
    font-size: 1.4em;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.power--unit {
    cursor: pointer;
}

.power--zone-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    min-height: 1px;
    z-index: -1;
}

.laps--cont {
    margin: 0em auto 4em;
}

.lap--item {
    border-bottom: 1px solid var(--white-opacity-01);
}

.laps--header {
    display: grid;
    grid-template-columns: 2em repeat(8, 1fr);
    justify-items: center;
    align-items: center;
    padding: 0 1em;
    height: 2em;
    margin: 4em auto 0;
    max-width: var(--inner-max-width);
}

.lap--item--inner {
    display: grid;
    grid-template-columns: 2em repeat(8, 1fr);
    justify-items: center;
    align-items: center;
    padding: 0 1em;
    height: 4em;
    margin: 0 auto;
    max-width: var(--inner-max-width);
}

.lap--value {
    font-size: 1.2em;
}

.lap--power {
    font-weight: bold;
}

.library--switch--group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
}

.library--switch {
    cursor: pointer;
}

.library--switch--heading {
    font-size: 1.1em;
    text-transform: uppercase;
    line-height: 5em;
    text-align: center;
    letter-spacing: 1px;
}

.library--switch .library--switch--heading {
    color: var(--gray);
}

.library--switch.active .library--switch--heading {
    color: #fff;
}

#workouts {
    display: none;
    margin-top: 20px;
}

#ride-report {
    display: none;
}

#workout-editor {
    display: none;
}

#workouts.active {
    display: block;
}

#ride-report.active {
    display: block;
}

#workout-editor.active {
    display: block;
}
/* END Ride Report */

/* Editor */
.editor-cont {
    --editor-max-width: 48em;
    font-size: 1rem;
    width: 100%;
}

.editor--row, .editor--form--group {
    max-width: var(--editor-max-width);
    width: 100%;
    margin: 0 auto;
}

.editor--meta {
    display: grid;
    background-color: var(--chrome);
    padding: 0.5em 0;
}

.editor--form--group {
    display: grid;
    align-items: center;
}

.editor--input {
    font-size: 1.3em;
    line-height: 1.3em;
    margin: 1em 1em;
    color: #fff;
    background-color: var(--dark);
    background-color: var(--chrome);
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--yellow);
}

.editor--label {
    text-align: left;
    margin: 1em 1.3em 0;
    line-height: 1.3em;

    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}

.editor--label--textarea {
    align-self: start;
    margin-top: 1.5em;
}

.editor--head {
    margin: 0 0 1em 0;
    background-color: var(--chrome);
}

.editor--row {
    display: grid;
    grid-template-columns: 10% repeat(6, 15%);
}

.editor--th {
    font-size: 1em;
    font-weight: bold;
    padding: 0.5em 0.5em;
    cursor: default;
}

.editor--td {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
    width: 100%;
    padding: 0.5em 0.5em;
    line-height: 1.3em;
    text-align: center;
    background-color: var(--dark);
    border: none;
}

.editor--td.power, .editor--td.ramp {
    font-size: 1.4em;
    font-weight: bold;
}

.editor--header {
    font-size: 1em;
    padding: 1.5em 1em 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    background-color: var(--chrome);
}

.editor--static {
    cursor: default;
}

.editor--icon {
    margin: 1em 2em;
    cursor: pointer;
}

.editor--save {
    fill: var(--gray);
    cursor: not-allowed;
}

.editor--radio {
    display: none;
    width: 1em;
    height: 1em;
    cursor: pointer;
}

.editor--radio.selected {
    display: block;
}
/* END Editor */
/* END Workouts Page */

/* Settings Page */
#settings-page {
    font-size: 1rem;
    line-height: 1.4em;
    --inner-max-width-fixed: 480px;
    --inner-max-width: 48em;
    --inner-max-wider: 60em;
    --size--icon--global: 2.4em;
}

.settings-tiles--cont {
    display: flex;
    max-width: var(--inner-max-width);
    margin: 0 auto;

    --width--settings-tile: 12em;
    --height--settings-tile: 12em;

    --font-size--label: 1.2em;
    --font-size--input: 3em;
    --height--label: 3em;
    --height--input: 1em;
}

.settings-tile {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr 1fr;

    align-items: center;
    justify-content: center;

    width: var(--width--settings-tile);
    height: var(--height--settings-tile);
    padding: 5px 5px;
    margin: 10px auto;

    text-align: center;
    border-radius: 50%;
    border: 1px solid var(--white-opacity-01);
}

.settings-tile--label {
    display: block;
    font-size: var(--font-size--label);
    font-weight: bold;
    text-transform: uppercase;
    height: var(--height--label);
    line-height: var(--height--label);
}

.settings-tile--input {
    font-size: var(--font-size--input);
    height: var(--height--input);
    width: 100%;
    font-weight: bold;
    text-align: center;

    color: var(--white);
    background-color: var(--dark);
    border: none;
}

#ftp-value {
    color: var(--yellow);
}

.settings-tile--btn {
    font-size: var(--font-size--label);
    height: var(--height--label);
    letter-spacing: 1px;
    padding: 0 14px;

    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
}

.settings-btn-row {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: var(--inner-max-width);
    margin: 0 auto 10px;
}

.settings-switch-btn-cont {
    margin: 10px auto;
    font-size: 1em;
}

.settings-switch-bnt {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 12em;
    height: 4em;

    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 50%;
    cursor: pointer;
}

.row-border {
    border-top: 1px solid var(--white-opacity-01);
}

.border-top {
    border-top: 1px solid var(--white-opacity-01);
}

.device-card {
    --settings-row-max-width: 60em;
    --font-size-value: 1.2em;
    --line-height--device-card--tile: 1.2em;
    --grid-template-columns--device-card--body--row: 5em 2fr 1fr 1.1fr 1fr 1fr;
    --size--icon: 2.7em;
    border-bottom: 1px solid var(--white-opacity-01);
}

.device-card--cont {
    max-width: var(--settings-row-max-width);
    margin: 0 auto;
    padding: 0.2em 0 0.8em 0;
}

.device-card--header--label {
    font-size: 1.1em;
    line-height: 3em;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.device-card--body--row {
    display: grid;
    grid-template-columns: var(--grid-template-columns--device-card--body--row);
    padding: 0 5px 0 0;
}

.ant-not-supported {
    display: none!important;
}

.device-card--body--row.second {
    margin-top: 1.5em;
}

.device-card--tile {
    text-align: left;
}

.device-card--tile--header {
    font-size: var(--font-size--device-card--tile--header);
    line-height: 1em;
    margin-bottom: 4px;
}

.device-card--tile--value {
    font-size: var(--font-size-value);
    font-weight: bold;
}

.switch--btn--group {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-rows: 1fr 1fr 1fr;
    margin: 8rem auto 6rem;
}
.switch--btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: left;
    align-items: center;
    max-width: var(--settings-row-max-width);
    margin: 0 auto;
    padding: 0 4rem;
    height: 100%;
}

.switch--btn--label {
    font-size: 1.1em;
    line-height: 3em;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.switch--btn--value {
    display: block;
    margin: 1em auto 1em;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

.sound--row {
    display: flex;
    vertical-align: baseline;
    justify-content: center;
    max-width: var(--inner-max-width);
    margin: 0em auto;
}

.sound--row--item {
    max-width: 25%;
    width: 7rem;
    height: 3rem;
    line-height: 3rem;
    margin: 1rem 0rem;
}

.sound--control {
    cursor: pointer;
}

.sound--volume {
    font-size: 2rem;
    font-weight: bold;
    line-height: 3rem;
}

.connection-icon-switch--icon {
    fill: #fff;
    width: var(--size--icon);
    height: var(--size--icon);
}

.connection-icon-switch--icon.disabled {
    fill: var(--gray);
}

.connection-icon-switch--indicator {
    width: 1em;
    height: 1em;
    margin: 5px auto;
    border-radius: 50%;
}

.source-switch {
    display: block;
    color: var(--gray);
    cursor: pointer;
}

.source-switch.active {
    color: #fff;
}

/* ANT Device Request */
#ant-device-request {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}
#ant-device-request.active {
    display: block;
}
.device-request--wrapper {
    display: inline-block;

    font-size: 1rem;
    --grid-template-columns--device-request--list: 1fr 1fr 1fr;

    max-width: 100%;
    width: 48em;
    background-color: var(--chrome);
    box-shadow: 1px 2px 4px 0px rgba(0,0,0, 0.2);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.device-request--list {
    min-height: 100px;
}
.device-request--item {
    display: grid;
    grid-template-columns: var(--grid-template-columns--device-request--list);
    height: 3em;
    align-items: center;
    cursor: pointer;
}
.device-request--item.active {
    background-color: var(--black-opacity-01);
}
.device-request--item--protocol {
    font-size: 1.2em;
}
.device-request--item--number {
    font-size: 1.2em;
    font-weight: bold;
}
.device-request--item--type {
    font-size: 1.2em;
}
.device-request--item--type {
}
.device-request--controls {
    display: grid;
    grid-template-columns: 60px 1fr 11em 11em;
    align-items: center;
    padding: 1em 1em;
    border-top: 1px solid var(--black-opacity-01);
}
.device-request--status {
    justify-self: flex-start;
}
.device-request--controls button {
    margin: 0 1em;
    height: 3em;
    width: 9em;
    justify-self: flex-end;

    font-size: 1em;
    text-transform: uppercase;
    font-family: var(--ss-font-family);
    color: #fff;
    background-color: var(--chrome);
    border: 1px solid var(--white-opacity-01);
    border-radius: 3px;
    cursor: pointer;
}
.device-request--cancel {
}
.device-request--pair {
}
/* END ANT Device Request */

/* User Auth */
.auth {
    /* display: grid; */
    display: none;
    min-height: 300px;

    .input {
        margin: 1rem 0;
        padding: 0 1rem;
        height: 3rem;
        color: var(--dark)
    }
    .btn {
        margin: 1rem 0;
        padding: 0 1rem;
        height: 3rem;
        text-transform: none;
    }

    h2 {
        height: 3rem
    }
    a {
        color: #bbb;
        text-transform: uppercase;
        cursor: pointer;
    }
    a, p {
        font-size: 1.2rem;
    }
    h2 {
        font-size: 1.2rem;
        line-height: 3rem;
        text-transform: uppercase;
    }
    #login--form--section.active, #signup--form--section.active {
        display: block;
    }
    #login--form--section, #signup--form--section {
        display: none;
    }
    .tab--button {
        font-size: 1.2rem;
        line-height: 3rem;
        margin: 1rem;
        height: 3rem;
        text-decoration: underline;
        cursor: pointer;
    }
    .form--submit--section {
        display: flex;
        justify-content: center;
    }
    .webauthn--section {
        margin: 1rem 0;
    }
}

/* App Connections */
.app-connections {
    margin: 1.4em auto;
}

.app-connection {
    display: flex;
    max-width: var(--inner-max-width);
    margin: 0 auto;
    align-items: center;
    padding: 1em 1em;
}

.app-connection--icon {
    width: 4em;
    height: 4em;
    margin-right: 1em;
}

.app-connection--heading {
    font-size: 1em;
    text-align: left;
    text-transform: uppercase;
}

.app-connection--content {
}

/* About */
#about {
    font-size: 1.2rem;
    margin: 10px auto 0;
    padding: 10px 10px;
    max-width: var(--inner-max-width);
    text-align: center;
}
.about--text {
    font-size: 1em;
    line-height: 1.4em;
    margin-bottom: 3em;
}
.about--version {
    margin: 0.5em auto;
}
.about--links {
    margin: 0.5em auto;
}

.license {
    letter-spacing: 1px;
}
/* END Settings Page */


/* Media Queries */
/* IPHONE 5/SE */
@media all
and (max-width: 340px)
and (max-height: 620px) {
    /* Home Page MQ */
    #home-page-wrapper {
        grid-template-rows: 40px 2fr 1fr 1fr 70px 80px;
    }

    .data-tiles {
        --data-tile--heading-margin: 0 auto 0.4em;
    }

    .mode-selector {
        margin: 1em auto;
    }

    .fixed-bottom {
        --controls-height: 4em;
        --menu-height: 4em;
        --fb-total-height: 8em;
    }

}

/* IPHONE 6/7/8 Plus */
@media all
and (min-width: 414px)
and (min-height: 730px) {
    /* Home Page MQ */
    .data-tiles {
        font-size: 1.2rem;
    }

}

/* Small Tablets, Laptop (split screen) */
@media all
and (min-width: 600px)
and (min-height: 600px) {
/* and (min-height: 1024) */
    /* Home Page MQ */
    .data-tiles {
        display: grid;
        grid-template-columns: repeat(5, 20%);
        grid-template-rows: repeat(2, 1fr) 2rem;
        justify-items: center;
        align-items: center;
        --data-tile-small--value-font-size: 3.0em;
    }

    .data-tile--switch--group {
        grid-area: 3 / 1 / 3 / 13;
    }

    #data-tile--power-avg {
        grid-area: 1 / 1 / 1 / 1;
    }
    #data-tile--power {
        grid-area: 1 / 2 / 1 / 2;
    }
    #data-tile--interval-time {
        grid-area: 1 / 3 / 1 / 3;
    }
    #data-tile--heart-rate {
        grid-area: 1 / 4 / 1 / 4;
    }
    #data-tile--slope {
        grid-area: 2 / 1 / 2 / 1;
    }
    #data-tile--target {
        grid-area: 2 / 2 / 2 / 2;
    }
    #data-tile--elapsed-time {
        grid-area: 2 / 3 / 2 / 3;
    }
    #data-tile--cadence {
        grid-area: 2 / 4 / 2 / 4;
    }
    #data-tile--speed {
        grid-area: 1 / 5 / 1 / 5;
    }
    #data-tile--distance {
        grid-area: 2 / 5 / 2 / 5;
    }

    #data-tile--smo2 {
        grid-area: 1 / 1 / 1 / 2;
    }
    #data-tile--thb {
        grid-area: 2 / 1 / 2 / 2;
    }
    #data-tile--core-body-temperature {
        grid-area: 1 / 5 / 1 / 5;
    }
    #data-tile--skin-temperature {
        grid-area: 2 / 5 / 2 / 5;
    }

    .data-tile-small--unit {
        display: none;
    }

    .device-card {
        --grid-template-columns--device-card--body--row: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

/* IPad, Galaxy Tab S, Portrait */
@media all
and (min-width: 768px)
and (min-height: 1024px)
and (orientation: portrait) {
    /* Home Page MQ */
    #home-page-wrapper {
        grid-template-rows: 50px 2.5fr 1fr 1fr 108px 100px;
    }

    .data-tiles {
        font-size: 2rem;

        --data-tile-small--heading-font-size: 0.8em;
        --data-tile-small--value-font-size: 2em;

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(3, 1fr) 2rem;
    }

    .data-tile--switch--group {
        grid-area: 4 / 1 / 4 / 13;
    }

    #data-tile--power {
        grid-area: 1 / 1 / 1 / 5;
    }
    #data-tile--interval-time {
        grid-area: 1 / 5 / 1 / 9;
    }
    #data-tile--heart-rate {
        grid-area: 1 / 9 / 1 / 13;
    }
    #data-tile--target {
        grid-area: 2 / 1 / 2 / 5;
    }
    #data-tile--elapsed-time {
        grid-area: 2 / 5 / 2 / 9;
    }
    #data-tile--cadence {
        grid-area: 2 / 9 / 2 / 13;
    }
    #data-tile--slope {
        grid-area: 3 / 4 / 3 / 7;
    }
    #data-tile--power-avg {
        grid-area: 3 / 1 / 3 / 4;
    }
    #data-tile--speed {
        grid-area: 3 / 7 / 3 / 10;
    }
    #data-tile--distance {
        grid-area: 3 / 10 / 3 / 13;
    }
    #data-tile--smo2 {
        grid-area: 3 / 4 / 3 / 7;
    }
    #data-tile--thb {
        grid-area: 3 / 1 / 3 / 4;
    }
    #data-tile--core-body-temperature {
        grid-area: 3 / 7 / 3 / 10;
    }
    #data-tile--skin-temperature {
        grid-area: 3 / 10 / 3 / 13;
    }

    .data-tile-small--unit {
        display: none;
    }

    .graph--heading {
        font-size: 1.2em;
        line-height: 2em;
    }

    #graph--info--cont {
        font-size: 1.6em;
    }

    #targets {
        --number-input-height: 4rem;
        --mode-selector-margin: 1.7em auto;
    }
}

/* IPad, Lanscape */
@media only screen
and (min-width: 1024px)
and (min-height: 768px) {
    /* Home Page MQ */
    #home-page-wrapper {
        grid-template-rows: 50px 2fr 1fr 1fr 98px 100px;
    }

    .data-tiles {
        font-size: 1.6rem;
    }

    #graph--info--cont {
        font-size: 1.6em;
    }
}

/* IPad Pro, Portrait */
@media all
and (min-width: 1024px)
and (min-height: 1366px) {
    /* Home Page MQ */
    #home-page-wrapper {
        grid-template-rows: 50px 2.5fr 1fr 1fr 108px 100px;
    }

    .data-tiles {
        font-size: 2.7rem;
    }

    .graph--heading {
        font-size: 1.2em;
        line-height: 2em;
    }

    #targets {
        --number-input-height: 4rem;
        --mode-selector-margin: 1.7em auto;
    }
}


/* IPad Pro, Landscape */
@media only screen
and (min-width: 1360px)
and (min-height: 1020px)
and (orientation: landscape) {
    /* Home Page MQ */

    .data-tiles {
        font-size: 2.7rem;
    }
}






/* Macbook Air, regular */
@media only screen
and (min-width: 1280px)
and (min-height: 650px) {
}

/* Macbook Air, fullscreen */
@media only screen
and (min-width: 1200px)
and (min-height: 600px) {
    .data-tiles {
        font-size: 2rem;
        --data-tile--heading-font-size: 0.8em;
        --data-tile-small--heading-font-size: 0.8em;
        --data-tile--heading-margin: 0 auto 0.5em;
    }
}

/* HD Laptop, fullscreen */
@media only screen
and (min-width: 1360px)
and (min-height: 700px) {
    .data-tiles {
        font-size: 2.4rem;
    }
}

@media only screen
and (min-width: 1600px)
and (min-height: 800px) {
    .data-tiles {
        font-size: 2.6rem;
    }

    #settings-page {
        font-size: 1.1rem;
    }
}


@media only screen
and (min-width: 1800px)
and (min-height: 850px) {
    /* FHD */

    #home-page-wrapper {
        grid-template-rows: 5em 2.5fr 1fr 1fr 14em 14em;
    }

    .connections-header {
        font-size: 1.2rem;
    }

    .data-tiles {
        font-size: 3rem;
    }

    .data-tile--switch--group {
        height: 1em;
    }
    .data-tile--switch {
        height: 2em;
        width: 3em;
    }
    .data-tile--switch .data-tile--switch--icon {
        width: 0.5em;
        height: 0.5em;
    }


    #graph--info--cont {
        font-size: 2.0em;
    }

    #targets {
        --number-input-height: 5rem;
        --mode-selector-margin: 2em auto;
        --inner-max-width: 50em;
    }

    .watch {
        padding: 1em;
    }

    #settings-page {
        font-size: 1.2rem;
    }

    .editor-cont {
        font-size: 1.2rem;
    }

    .fixed-bottom {
        font-size: 1.4rem;
    }
}

@media only screen
and (min-width: 1900px)
and (min-height: 900px) {
    /* FHD */
    .data-tiles {
        font-size: 3.2rem;
    }
}

@media only screen
and (min-width: 2400px)
and (min-height: 1200px) {
    /* QHD */
    #home-page-wrapper {
        grid-template-rows: 6em 2.5fr 1fr 1fr 16em 16em;
    }

    .connections-header {
        font-size: 1.6rem;
    }

    .switch--label {
        font-size: 1.5rem;
    }

    .data-tiles {
        font-size: 4.5rem;
    }

    .graph--heading {
        font-size: 1.8em;
        line-height: 2.5em;
    }

    .workout--short-info {
        line-height: 1.5em;
    }

    #graph--info--cont {
        font-size: 2.4em;
    }

    #targets {
        font-size: 1.4rem;
        --number-input-height: 6rem;
        --mode-selector-margin: 2em auto;
        --inner-max-width: 50em;
        --mode-selector-padding: 0 1em;
    }

    .fixed-bottom {
        font-size: 1.6rem;
    }

    #workout-name {
        line-height: 2em;
    }

    #settings-page {
        font-size: 1.6rem;
    }

    #workouts-page {
        font-size: 1.6rem;
    }

    .editor-cont {
        font-size: 1.6rem;
    }
}

@media only screen
and (min-width: 3000px)
and (min-height: 1600px) {
    /* 4K UHD */

    #home-page-wrapper {
        grid-template-rows: 10em 2.5fr 1fr 1fr 24em 24em;
    }

    .data-tiles {
        font-size: 6.4rem;
    }

    .connections-header {
        font-size: 2.4rem;
    }

    #graph--info--cont {
        font-size: 4.0em;
    }

    #targets {
        font-size: 2.4rem;
        --number-input-height: 8rem;
        --mode-selector-margin: 2em auto;
        --inner-max-width: 44em;
        --mode-selector-padding: 0 1em;
    }

    #settings-page {
        font-size: 2.4rem;
    }

    #workouts-page {
        font-size: 2.4rem;
    }

    .editor-cont {
        font-size: 2.4rem;
    }

    .fixed-bottom {
        font-size: 2.4rem;
    }
}

/* IPhone Pro 12, landscape */
@media all
and (max-height: 428px)
and (min-width: 500px) {

    #home-page-wrapper {
        display: grid;
        grid-template-columns: 50px 1fr 1fr;
        grid-template-rows: 2fr 1fr 1fr 50px;
    }

    page-group {
        display: block;
        padding-right: 100px;
    }

    #home-page .page-header {
        grid-row-start: 1;
        grid-row-end: -1;

        grid-column-start: 1;
        grid-column-end: 1;

        flex-direction: column;
    }

    .connection-switch--indicator {
        margin: 0;
    }

    .connection-switch--label {
        display: none;
    }

    .data-tiles {
        font-size: 1rem;

        grid-area: 1 / 2 / 2 / 4;

        display: grid;
        grid-template-columns: 1fr 1fr 1.2fr 1fr 1fr;
        grid-template-rows: repeat(2, 1fr) 2rem;

        justify-content: center;
        justify-items: center;

        --data-tile-small--value-font-size: 3.0em;
        --data-tile--heading-margin: 0 auto 0.3em;
    }
    .data-tile--switch--group {
        grid-area: 3 / 1 / 3 / 13;
    }

    #data-tile--power-avg {
        grid-area: 1 / 1 / 1 / 1;
    }
    #data-tile--smo2 {
        grid-area: 1 / 1 / 1 / 1;
    }
    #data-tile--power {
        grid-area: 1 / 2 / 1 / 2;
    }
    #data-tile--interval-time {
        grid-area: 1 / 3 / 1 / 3;
    }
    #data-tile--heart-rate {
        grid-area: 1 / 4 / 1 / 4;
    }
    #data-tile--slope {
        grid-area: 2 / 1 / 2 / 1;
    }
    #data-tile--thb {
        grid-area: 2 / 1 / 2 / 1;
    }
    #data-tile--target {
        grid-area: 2 / 2 / 2 / 2;
    }
    #data-tile--elapsed-time {
        grid-area: 2 / 3 / 2 / 3;
    }
    #data-tile--cadence {
        grid-area: 2 / 4 / 2 / 4;
    }
    #data-tile--speed {
        grid-area: 1 / 5 / 1 / 5;
    }
    #data-tile--distance {
        grid-area: 2 / 5 / 2 / 5;
    }
    #data-tile--core-body-temperature {
        grid-area: 1 / 5 / 1 / 5;
    }
    #data-tile--skin-temperature {
        grid-area: 2 / 5 / 2 / 5;
    }

    #graph-power {
        grid-area: 2 / 2 / 3 / 4;
    }

    #graph-workout {
        grid-area: 3 / 2 / 3 / 4;
    }

    #targets {
        grid-area: 4 / 2 / 4 / 4;
        --mode-selector-margin: 1em auto;
        --mode-selector-padding: 0 1em;
        padding: 0 0em;
    }

    .targets--cont {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        max-width: none;
        min-height: 100%;
    }

    .tab-btn-group {
        width: 50%;
        padding: 0 0 0 1em;
    }

    tab-group {
        width: 50%;
        padding: 0 1em 0 0em;
    }

    .fixed-bottom {
        --controls-height: 100vh;
        --menu-height: 100vh;
        --fb-total-height: 100vh;

        grid-area: 1 / 4 / 3 / 5;

        display: flex;
        top: 0;
        right: 0;
        width: 100px;
    }

    .controls {
        width: 40%;
        border-top: none;
    }

    .dark-theme .controls {
        background-color: var(--chrome);
    }

    .menu {
        width: 60%;
        background-color: var(--chrome);
    }

    .watch {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        height: 100vh;
        padding: 0;
        background-color: var(--chrome);
    }

    .menu--row {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        height: 100vh;
    }

    #workout-name {
        display: none;
    }
}

/* DOCK MOCK (wide and very short) */
@media all
and (max-height: 300px)
and (min-width: 1024px) {

    #home-page-wrapper {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        grid-template-rows: 1fr 1fr 4em;
    }

    page-group {
        display: block;
        padding-right: 12em;
    }

    #home-page .page-header {
        grid-area: 3 / 1 / 3 / 1;
        flex-direction: row;
    }

    .switch--indicator {
        margin: 0;
    }

    .switch--label {
        display: none;
    }
    .connection-switch--indicator {
        margin: 0px 1em 0px 0px;
    }
    .connection-switch--label {
        display: block;
    }

    .line {
        display: none;
    }

    /* Data views */
    .data-tiles {
        --data-tile--heading-margin: 0 auto 0.5em;

        grid-area: 1 / 2 / -1 / 3;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr 1fr 2rem;

        justify-items: center;
        align-items: center;

        --data-tile--heading-font-size: 0.8em;
        --data-tile-small--heading-font-size: 0.8em;
        background-color: var(--chrome);
    }

    #graph-workout {
        grid-area: 1 / 1 / 3 / 2;
    }

    #graph-power {
        display: block;
        grid-area: 1 / 3 / 3 / 4;
    }

    #targets {
        --mode-selector-padding: 0 1em;
        --mode-selector-margin: 0em auto;
        grid-area: 3 / 3 / 3 / 3;

        background-color: var(--chrome);
    }
    .targets--cont {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-colums: 1fr 1fr;
    }
    tab-btn-group {
        grid-area: 1 / 1 / 1 / 1;
    }
    tab-group {
        grid-area: 1 / 2 / 1 / 2;
        width: auto;
        padding: 0 0;
    }
    .btn {
        /* background-color: var(--dark); */
    }

    .fixed-bottom {
        --controls-height: 100vh;
        --menu-height: 100vh;
        --fb-total-height: 100vh;

        display: flex;
        top: 0;
        right: 0;
        width: 12em;
   }

    .controls {
        width: 50%;
    }

    .dark-theme .controls {
        background-color: var(--chrome);
    }

    .menu {
        width: 50%;
        background-color: var(--chrome);
    }

    .watch {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        height: var(--controls-height);
        padding: 0;
    }

    .menu--row {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        height: var(--menu-height);
    }

    #workout-name {
        display: none;
    }

    .workout-list--graph-cont {
        float: left;
        width: 50%;
    }
    .workout--description {
        float: right;
        width: 50%;
        padding: 1em 1em 0;
    }
}

@media only screen
and (max-height: 300px)
and (min-width: 1200px) {

    #home-page-wrapper {
    }

    .data-tiles {
        grid-template-columns: 19% 19% 24% 19% 19%;
    }
}

@media only screen
and (max-height: 300px)
and (min-width: 1600px) {
    .data-tiles {
        font-size: 1.3rem;
        --data-tile--heading-margin: 0 auto 0em;
    }
}


@media only screen
and (max-height: 300px)
and (min-width: 1900px) {

    .data-tiles {
        font-size: 1.6rem;
        --data-tile--heading-font-size: 0.8em;
        --data-tile-small--heading-font-size: 0.8em;
    }
}
/* END Media Queries */
